









































































































































































/* eslint-disable */
import {useActions, useGetters, useState} from '@u3u/vue-hooks'
import {defineComponent, onBeforeMount, ref} from '@vue/composition-api'

export default defineComponent({
  name: 'faqs-panel',

  setup(props, ctx) {
    const {hidePanel} = useActions('sidePanel', ['hidePanel'])
    const {currentLang} = useGetters(['currentLang'])
    const state = useState('faq', ['i18n'])
    const urlForms = ref('')
    const urlInt = ref('')
    const urlEp = ref('')
    const actions = useActions('faq', ['FETCH_I18N'])
    if (currentLang.value === 'de') {
      urlForms.value = '/kontakt-form/'
      urlInt.value = 'in-echtzeit/ausfalle-der-strassenbeleuchtung/'
      urlEp.value = 'in-echtzeit/ausfalle-und-service-unterbrechungen/'
    } else {
      urlForms.value = '/formulaire-de-contact/'
      urlInt.value = 'en-temps-reel/pannes-et-interruptions-de-service/'
      urlEp.value = 'en-temps-reel/signaler-une-panne-eclairage-public/'
    }
    onBeforeMount(() => {
      actions.FETCH_I18N()
    })
    const redirect = string => {
      hidePanel()
      ctx.root.$router.push({
        path: `/${currentLang.value}/${string}`,
      })
    }
    const redirectForm = string => {
      hidePanel()
      ctx.root.$router.push({
        path: `/${currentLang.value}/${string}`,
      })
    }

    return {
      redirect,
      redirectForm,
      i18n: state.i18n,
      urlForms,
      urlInt,
      urlEp,
    }
  },
})
