var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n)?_c('div',{staticClass:"faqs-panel"},[_c('h3',[_vm._v(_vm._s(_vm.i18n.panel.title))]),_c('br'),_vm._v(" "+_vm._s(_vm.i18n.panel.subtitle)+" "),_c('br'),_c('br'),_c('span',{staticClass:"icons__energy",staticStyle:{"color":"#5dad98"}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 80 80","symbol":"icons-gazflood"}}),_vm._v(" "+_vm._s(_vm.i18n.panel.gaz)+" ")]),_c('br'),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.i18n.panel.callUs))]),_c('br'),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.panel.francophone)}}),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.panel.germanophone)}}),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.i18n.panel.state)+" "),_c('br'),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.panel.urgence)}}),_c('br'),_c('br'),_c('br'),_c('span',{staticClass:"icons__energy",staticStyle:{"color":"#e84e0f"}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 80 80","symbol":"icons-electricityflood"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.panel.elec)}})]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.panel.danger)}}),_c('br'),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.panel.reportOutage)}}),_c('br'),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.panel.luminaire)}}),_vm._v(" "),_c('br'),_c('br'),_c('g-action',{attrs:{"content":{
      label: _vm.i18n.panel.btnReportDOM,
      tag: 'button',
      icon: 'arrow',
      modifiers: ['big'],
    }},nativeOn:{"click":function($event){return _vm.redirect(_vm.urlInt)}}}),_c('br'),_c('br'),_c('g-action',{attrs:{"content":{
      label: _vm.i18n.panel.btnReportEP,
      tag: 'button',
      icon: 'arrow',
      modifiers: ['big'],
    }},nativeOn:{"click":function($event){return _vm.redirect(_vm.urlEp)}}}),_c('br'),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.panel.questions)}}),_c('br'),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.panel.serviceClient)}}),_c('br'),_c('br'),_c('g-action',{attrs:{"content":{
      label: _vm.i18n.panel.form,
      tag: 'button',
      icon: 'arrow',
      modifiers: ['big'],
    }},nativeOn:{"click":function($event){return _vm.redirectForm(_vm.urlForms)}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }