



































































































































































































































































































































import VInput from '@/components/form/VInput.vue'
import VTextarea from '@/components/form/VTextarea.vue'
import VSelect from 'vue-select'
import VDatepicker from '@/components/form/VDatepicker.vue'

import dayjs from 'dayjs'
import { useState, useMutations } from '@u3u/vue-hooks'
import {
  defineComponent,
  onUnmounted,
  ref,
  onMounted,
  reactive,
  watch,
  computed,
  toRefs,
} from '@vue/composition-api'
import { ConnectionMeterTemporary } from '@/inc/types'

export default defineComponent({
  components: {
    VTextarea,
    VInput,
    VSelect,
    VDatepicker,
  },

  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'temporaryMeter',
        'subProcessus',
        'temporaryInfo',
        'i18n',
        'request',
      ]),
    }
    const mutations = {
      ...useMutations('connection', [
        'ADD_TEMPORARY_METER',
        'SET_TEMPORARY_INFO',
        'UPDATE_TEMPORARY_METER',
        'SET_SUBPROCESSUS',
      ]),
    }

    const form = ref<HTMLFormElement | null>(null)
    const temporaryInfo = reactive({ ...state.temporaryInfo.value })
    const temporaryMeter = ref<ConnectionMeterTemporary>({
      power: null,
      amperage: null,
      tarif: null,
      phasesSlug: null,
    })
    const start = ref(
      temporaryInfo.startDate ? new Date(temporaryInfo.startDate) : null
    )
    const end = ref(
      temporaryInfo.endDate ? new Date(temporaryInfo.endDate) : null
    )
    const refresher = ref(0)

    const phases = [
      {
        value: 'Mono 230V-1N400V',
        label:
          state.i18n.value.compteursElectriques.raccordementProvisoire
            .typeCompteur.mono,
      },
      {
        value: 'Triphasé 3N400V',
        label:
          state.i18n.value.compteursElectriques.raccordementProvisoire
            .typeCompteur.tri,
      },
    ]

    const mono = [
      { value: 4.6, label: 4.6 },
      { value: 6.9, label: 6.9 },
      { value: 9.2, label: 9.2 },
      { value: 11.5, label: 11.5 },
      { value: 14.5, label: 14.5 },
    ]

    const tri = [
      { value: 10.4, label: 10.4 },
      { value: 12.5, label: 12.5 },
      { value: 14.5, label: 14.5 },
      { value: 16.6, label: 16.6 },
      { value: 18.7, label: 18.7 },
      { value: 20.8, label: 20.8 },
      { value: 22.9, label: 22.9 },
      { value: 24.9, label: 24.9 },
      { value: 27.0, label: 27.0 },
      { value: 29.1, label: 29.1 },
      { value: 31.2, label: 31.2 },
      { value: 33.3, label: 33.3 },
      { value: 35.3, label: 35.3 },
      { value: 37.4, label: 37.4 },
      { value: 39.5, label: 39.5 },
      { value: 41.6, label: 41.6 },
      { value: 43.6, label: 43.6 },
      {
        value: '+ de 43.6',
        label:
          state.i18n.value.compteursElectriques.raccordementProvisoire
            .plus43kVA,
      },
    ]

    const types = [
      {
        value: 'Simple tarif (mono-horaire)',
        label:
          state.i18n.value.compteursElectriques.raccordementProvisoire.tarif
            .monoHoraire,
      },
      {
        value: 'Double tarif (bi-horaire > heures creuses/heures pleines)',
        label:
          state.i18n.value.compteursElectriques.raccordementProvisoire.tarif
            .biHoraire,
      },
    ]

    const checkNext = () => {
      if (
        temporaryMeter.value.amperage &&
        temporaryMeter.value.power &&
        temporaryMeter.value.tarif &&
        temporaryMeter.value.phasesSlug &&
        form.value!.checkValidity()
      ) {
        if (state.temporaryMeter.value) {
          mutations.UPDATE_TEMPORARY_METER(temporaryMeter.value)
        } else {
          mutations.ADD_TEMPORARY_METER(temporaryMeter.value)
        }
        mutations.SET_TEMPORARY_INFO(temporaryInfo)
        ctx.root.$emit('connection:ready', true)
      } else {
        ctx.root.$emit('connection:ready', false)
      }
    }

    const calculateAmps = computed(() => {
      if (temporaryMeter.value.power) {
        let amps: any = 0
        if (temporaryMeter.value.phasesSlug === 'Mono 230V-1N400V') {
          amps = (temporaryMeter.value.power / 230) * 1000
          amps = Math.round(amps)
        } else if (temporaryMeter.value.phasesSlug === 'Triphasé 3N400V') {
          if (String(temporaryMeter.value.power) === '+ de 43.6') {
            amps = '+ de 63'
          } else {
            amps = (temporaryMeter.value.power / (Math.sqrt(3) * 230)) * 1000
            amps = Math.round(amps)
          }
        }
        temporaryMeter.value.amperage = amps

        return amps
      }

      return ''
    })

    const onTypeChange = () => {
      temporaryMeter.value.power = null
    }

    const setStartDate = value => {
      temporaryInfo.startDate = value.format('YYYY-MM-DD')
      temporaryInfo.endDate = null
      end.value = null
      refresher.value += 1
      mutations.SET_TEMPORARY_INFO(temporaryInfo)
    }

    const setEndDate = value => {
      temporaryInfo.endDate = value.format('YYYY-MM-DD')
      mutations.SET_TEMPORARY_INFO(temporaryInfo)
    }

    watch(temporaryMeter.value, meter => {
      checkNext()
    })

    watch(
      () => toRefs(temporaryInfo),
      ({ startDate, endDate }) => {
        checkNext()
      }
    )

    onMounted(() => {
      Object.assign(temporaryMeter.value, state.temporaryMeter.value)
      checkNext()
    })

    onUnmounted(() => {
      mutations.SET_SUBPROCESSUS('CHAN_PROVI')
    })

    return {
      start,
      end,
      temporaryInfo,
      form,
      phases,
      types,
      mono,
      tri,
      refresher,
      checkNext,
      temporaryMeter,
      onTypeChange,
      setStartDate,
      setEndDate,
      calculateAmps,
      dayjs,
      request: state.request,
      i18n: state.i18n,
    }
  },
})
