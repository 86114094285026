var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.content)?_c('div',{staticClass:"mb-xl",attrs:{"id":"faq"}},[(_vm.content.breadcrumb)?_c('breadcrumb',{attrs:{"content":_vm.content.breadcrumb,"faq":true}}):_vm._e(),_c('div',{staticClass:"faq-page wrapper row"},[_c('div',{staticClass:"col-xs-20 col-l-14"},[(_vm.content.header && _vm.content.header.title)?_c('h1',{staticClass:"content__title mb-s",domProps:{"innerHTML":_vm._s(
          _vm.$options.filters && _vm.$options.filters.linebreak(_vm.content.header.title)
        )}}):_vm._e(),_c('search-module',{staticClass:"mb-s",attrs:{"in-modal":false}}),_c('FaqContact')],1),_c('div',{staticClass:"col-xs-20 col-l-6"},[(_vm.i18n.tip)?_c('TipsNew',{attrs:{"content":{
          icon: { name: 'icons-warning' },
          title: _vm.i18n.tip.title,
          htmltext: _vm.i18n.tip.text,
          button: { label: _vm.i18n.tip.btn, icon: 'arrow' },
        },"sticky":true}}):_vm._e()],1)]),_c('FlexibleContents',{attrs:{"flexibles":_vm.content.flexibleContent}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }