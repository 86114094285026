































































































































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'vertical-timeline',
  components: {},
  props: {
    content: Object,
  },
})
