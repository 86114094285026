

















































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import BlockContentImage from '@/components/ui/BlockContentImage.vue'
import BigBnt from '@/components/ui/BigBnt.vue'
import Temporary from '@/components/connection/new-screens/meters/Temporary.vue'

export default defineComponent({
  name: 'faq-contact',
  components: { Temporary, BlockContentImage, 'big-btn': BigBnt },
  setup() {
    const state = useState('faq', ['i18n'])
    const actions = useActions('faq', ['FETCH_I18N'])
    const { currentLang } = useGetters(['currentLang'])
    const urlForms = ref('')
    if (currentLang.value === 'de') {
      urlForms.value = '/kontakt-form/'
    } else {
      urlForms.value = '/formulaire-de-contact/'
    }
    onBeforeMount(() => {
      actions.FETCH_I18N()
    })

    return {
      ...useGetters(['chrome']),
      i18n: state.i18n,
      urlForms,
    }
  },
})
