var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n)?_c('div',{staticClass:"container faq-contact mb-s"},[_c('h3',{staticClass:"faq-contact__title mt-m mb-xxs"},[_vm._v(" "+_vm._s(_vm.chrome.faqContact.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.chrome.faqContact.subtitle))]),_c('div',{staticClass:"row"},[(_vm.i18n.blocks)?_c('block-content-image',{staticClass:"col me-s",attrs:{"content":{
        title: _vm.i18n.blocks.chat,
        icon: 'chat',
        url: null,
      }}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.i18n.blocks.chatContent)}})]):_vm._e(),(_vm.i18n.blocks)?_c('block-content-image',{staticClass:"col me-s",attrs:{"content":{
        title: _vm.i18n.blocks.email,
        icon: 'email',
        url: _vm.urlForms,
      }}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.i18n.blocks.emailContent)}})]):_vm._e(),(_vm.i18n.blocks)?_c('block-content-image',{staticClass:"col me-s",attrs:{"content":{
        title: _vm.i18n.blocks.tel,
        icon: 'telephone',
        url: null,
      }}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.i18n.blocks.telContent)}})]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }