var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'container-sticky': _vm.sticky && !_vm.switcher,
    'container-fixed': _vm.sticky && _vm.switcher,
    container: !_vm.sticky,
    'container-spacing-left': _vm.spacingLeft,
  }},[_c('aside',{class:{
      'tip__box-sticky': _vm.sticky && !_vm.switcher,
      'tip__box-fixed': _vm.sticky && _vm.switcher,
      tip__box: !_vm.sticky,
      'is-open': _vm.isOpen && _vm.sticky,
    }},[(_vm.isOpen && _vm.switcher)?_c('div',{staticClass:"tip__close",on:{"click":function($event){$event.preventDefault();return _vm.openClose($event)}}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 13 13","symbol":"ui-cross"}})]):_vm._e(),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"tip__icon",class:{ 'mb-xxxs': _vm.isOpen },attrs:{"symbol":_vm.content.icon.name || 'icons-info',"size":"0 0 24 24"},on:{"click":function($event){$event.preventDefault();return _vm.openClose($event)}}}),(_vm.content.title && ((_vm.isOpen && _vm.switcher) || !_vm.switcher))?_c('div',{staticClass:"tip__title",domProps:{"innerHTML":_vm._s(_vm.content.title)}}):_vm._e(),(_vm.content.htmltext && ((_vm.isOpen && _vm.switcher) || !_vm.switcher))?_c('div',{staticClass:"wysiwyg tip__text mb-xxs",domProps:{"innerHTML":_vm._s(_vm.content.htmltext)}}):_vm._e(),(_vm.content.button && ((_vm.isOpen && _vm.switcher) || !_vm.switcher))?_c('CustomLink',{staticClass:"tip__button",attrs:{"content":{
        tag: 'button',
        label: _vm.content.button.label,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-large-right',
        },
      },"modifiers":['big', 'rectrounded', 'btn', 'btn', 'icon', 'arrow'],"type":"button"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onClick($event)}}}):(_vm.content.url && ((_vm.isOpen && _vm.switcher) || !_vm.switcher))?_c('CustomLink',{staticClass:"tip__button",attrs:{"content":{
        label: _vm.content.label,
        url: _vm.content.url,
        target: '_blank',
        icon: {
          size: '0 0 24 24',
          name: 'arrows-large-right',
        },
      },"modifiers":['big', 'rectrounded', 'btn', 'btn', 'icon', 'arrow'],"type":'externalLink'}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }