



























































































import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from '@vue/composition-api'

import Breadcrumb from '@/components/Breadcrumb.vue'
import FaqContact from '@/components/faq/Contact.vue'
import TipsNew from '@/components/TipsNew.vue'
import FaqsPanel from '@/components/faq/FaqsPanel.vue'
import SearchModule from '@/components/chrome/search/SearchModule.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'

/* eslint-enable camelcase */
/* eslint-disable */
export default defineComponent({
  name: 'faq',
  components: {
    SearchModule,
    TipsNew,
    FaqContact,
    Breadcrumb,
    FlexibleContents,
  },
  setup(_props, ctx) {
    const chrome = useState(['chrome'])
    const { content } = useGetters(['content'])
    const faqState = useState('faq', ['i18n'])
    const faqActions = useActions('faq', [
      'FETCH_I18N',
      'fetchCategories',
      'setPreselected',
    ])
    const { getCat } = useGetters('faq', ['getCat'])

    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])

    const i18n = ref(faqState.i18n)
    const themeParam = ref(ctx.root.$route.query.theme)

    onBeforeMount(() => {
      faqActions.FETCH_I18N()
    })

    onMounted(async () => {
      await faqActions.setPreselected('')
      await faqActions.fetchCategories()
      if (themeParam.value) {
        const theme = await getCat.value(themeParam.value)
        if (theme) {
          const inBc = content.value.breadcrumb.filter(function (elem) {
            return elem.label === theme.name
          })
          if (inBc.length === 0) {
            content.value.breadcrumb.push({
              label: theme.name,
              url: null,
            })
          }
        }
      }
    })

    // Methode pour afficher le panel
    const showFaqPanel = () => {
      resetPanel()
      showPanel({
        component: {
          template: FaqsPanel,
        },
        content: {},
      })
    }
    // L'event qui permet d'ouvrir le panel
    ctx.root.$on('tip:btn-click', () => showFaqPanel())

    return {
      chrome,
      content,
      i18n,
    }
  },
})
