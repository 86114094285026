























































































































import { defineComponent, ref } from '@vue/composition-api'
import { fr, de } from 'vuejs-datepicker/dist/locale'
import dayjs from 'dayjs'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'v-datepicker',
  components: {
    datepicker: () => import('vuejs-datepicker'),
  },
  props: {
    label: String,
    id: String,
    required: {
      type: Boolean,
      default: false,
    },
    name: String,
    disabledDates: Object,
    disabled: Boolean,
    initialDate: Date,
    openDate: Date,
    maximumView: {
      type: String,
      default: 'day',
    },
    minimumView: {
      type: String,
      default: '',
    },
    placeHolder: {
      type: String,
      default: 'jj / mm / aaaa',
    },
    format: {
      type: String,
      default: 'dd MMMM yyyy',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: 'fill-background',
    },
  },

  setup(props, ctx) {
    const date = ref(props.initialDate)
    const french = ref(fr)
    const deutch = ref(de)
    const { currentLang } = useGetters(['currentLang'])
    const onInput = () => {
      ctx.emit('input', dayjs(date.value))
    }

    return {
      date,
      french,
      deutch,
      currentLang,
      onInput,
    }
  },
})
