


























































































































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import CustomLink from '@/components/g/CustomLink.vue'
import { Tip } from '@/inc/types'

export default defineComponent({
  name: 'tips-new',
  components: { CustomLink },
  props: {
    content: {
      type: Object as () => Tip,
      required: true,
    },
    sticky: {
      type: Boolean,
      required: false,
      default: true,
    },
    spacingLeft: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, ctx) {
    const switcher = ref(false)
    const isOpen = ref(false)

    onMounted(() => {
      if (window.innerWidth < 960 && props.sticky) {
        switcher.value = true
      }
    })

    const openClose = () => {
      isOpen.value = !isOpen.value
    }

    const onClick = () => {
      ctx.root.$emit('tip:btn-click')
    }

    return {
      switcher,
      isOpen,
      openClose,
      onClick,
    }
  },
})
