
























































































import Accordion from '@/components/Accordion.vue'
import CookieTable from '@/components/CookieTable.vue'
import CtaBanner from '@/components/CtaBanner.vue'
import CtaRich from '@/components/g/CtaRich.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import DualText from '@/components/DualText.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import Steps from '@/components/home/Steps.vue'
import TextColor from '@/components/TextColor.vue'
import TextEnum from '@/components/TextEnum.vue'
import TextOnly from '@/components/TextOnly.vue'
import TextTitle from '@/components/TextTitle.vue'
import TripleCta from '@/components/TripleCta.vue'
import TwoBlocksLine from '@/components/TwoBlocksLine.vue'
import VerticalTimeline from '@/components/VerticalTimeline.vue'
import Video from '@/components/g/Video.vue'
import Wizard from '@/components/Wizard.vue'
import Wysiwyg from '@/components/g/Wysiwyg.vue'

import { defineComponent, onMounted } from '@vue/composition-api'
import { GenericObject, Pages } from '@/inc/types'
import CtaCustom from '@/components/g/CtaCustom.vue'

/* eslint-disable camelcase */
const subpages: Pages = {
  accordion: Accordion,
  cookie_table: CookieTable,
  cta_banner: CtaBanner,
  ctaRich: CtaRich,
  ctaCustom: CtaCustom,
  double_cta: DoubleCta,
  homeSteps: Steps,
  // map: TODO ADD map component
  // pictureTitle: TODO ADD pictureTitle component
  related_links: RelatedLinks,
  text_color: TextColor,
  text_enum: TextEnum,
  text_only: TextOnly,
  text_rich: DualText,
  text_shortcodes: Wysiwyg,
  text_title: TextTitle,
  timeline_vertical: VerticalTimeline,
  title: TextTitle,
  twoBlocksLine: TwoBlocksLine,
  triple_cta: TripleCta,
  v_header: Header,
  v_video: Video,
  wizard: Wizard,
}
/* eslint-enable camelcase */

export default defineComponent({
  name: 'flexible-contents',
  props: {
    flexibles: {
      type: Array as () => Array<GenericObject>,
      required: true,
    },
    customMap: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const isWrapped = cpt => {
      const wrapped = [
        'cta_rich',
        'cta_custom',
        'related_links',
        'v_video',
        'text_shortcodes',
      ]

      return wrapped.some(item => item === cpt)
    }

    onMounted(() => {
      function wrap(top, selector, bottom) {
        const matches = document.querySelectorAll('.wysiwyg table')
        for (let i = 0; i < matches.length; i++) {
          const modified = top + matches[i].outerHTML + bottom
          matches[i].outerHTML = modified
        }
      }

      // eslint-disable-next-line quotes
      wrap("<div class='table-wrapper'>", '.getWrapped', '</div>')
    })

    return {
      isWrapped,
      subpages,
    }
  },
})
