
















































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'v-textarea',
  props: {
    label: String,
    text: String,
    required: {
      type: Boolean,
      default: false,
    },
    name: String,
    id: String,
    placeholder: String,
    value: String,
    errors: {
      type: Array,
      default: () => [],
    },
    focusOutEvent: {
      type: Object,
      isActive: false,
      emitValue: '',
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const onFocusOut = () => {
      ctx.root.$emit(`${props.focusOutEvent.emitValue}`)
    }

    return { onFocusOut }
  },
})
