









































































































































































































import { BlockContentImage } from '@/inc/types'
import { useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'block-content-image',
  components: {},
  props: {
    content: {
      type: Object as () => BlockContentImage,
      required: true,
    },
  },

  setup(props, ctx) {
    const { currentLang } = useGetters(['currentLang'])
    const onClick = () => {
      if (props.content.url) {
        if (props.content.icon === 'email') {
          ctx.root.$router.push({
            path: `/${currentLang.value}/${props.content.url}`,
          })
          window.scroll({
            top: 0,
            behavior: 'smooth',
          })
        }
      }
    }

    return {
      onClick,
    }
  },
})
